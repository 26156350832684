import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "domSanitizer",
})
export class DomSanitizerPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    transform(value: any, ...args: unknown[]): unknown {
        if (!value) return null;
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
