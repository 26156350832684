<header class="topbar" id="topbar">
    <div class="inner-wrap">
        <div class="container-fluid">
            <div class="row d-flex justify-content-center">
                <div
                    class="col-sm-6 d-sm-flex d-none"
                    *ngIf="data.contact?.email"
                >
                    <div class="links-wrapper">
                        <a
                            class="text-white"
                            [attr.href]="'mailto:' + data.contact?.email"
                            target="_blank"
                        >
                            <i class="far fa-envelope me-2"></i>
                            <span
                                >Email :
                                <strong>{{ data.contact.email }}</strong></span
                            >
                        </a>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="data.contact?.socilaMedia?.length">
                    <ul class="links-list">
                        <li *ngFor="let item of data.contact.socilaMedia">
                            <a [attr.href]="item?.link" target="_blank">
                                <img
                                    [attr.src]="item?.lightIcon"
                                    width="16"
                                    alt="Yellzee"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
