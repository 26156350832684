<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="overview-content">
                <h3>
                    {{
                        data.overView?.title
                            ? data.overView.title
                            : "Application Overview"
                    }}
                </h3>
                <div class="bar"></div>
                <p
                    *ngIf="data.overView?.deescription"
                    [innerHTML]="data.overView.deescription | domSanitizer"
                ></p>
                <div class="overview-btn">
                    <button (click)="goToDownload()" class="default-btn">
                        Get It Now <span></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-6" *ngIf="data.overView?.image">
            <div class="overview-image text-center">
                <img
                    class="img img-fluid w-100"
                    [attr.src]="data.overView.image"
                    style="max-width: 400px"
                    alt="Yellzee"
                />
            </div>
        </div>
    </div>
</div>
