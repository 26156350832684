<div class="container">
    <div class="section-title">
        <h2>{{ data.features.headTitle }}</h2>
        <div class="bar"></div>
        <p>
            {{ data.features.headDeescription }}
        </p>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let item of data.features.items">
            <div class="single-features">
                <div class="icon">
                    <img [attr.src]="item.icon" [attr.alt]="item.title" />
                </div>
                <h3>{{ item.title }}</h3>
                <p>
                    {{ item.deescription }}
                </p>
            </div>
        </div>
    </div>
</div>
