<!-- Preloader -->
<app-preloader *ngIf="navigationLoading || backendLoading"></app-preloader>
<!-- Topbar -->
<app-topbar *ngIf="data" [data]="data"></app-topbar>
<!-- Navbar -->
<app-navbar *ngIf="data?.navigation?.length" [data]="data"></app-navbar>
<!-- Router Outlet -->
<router-outlet></router-outlet>
<!-- Footer -->
<app-footer *ngIf="data" [data]="data"></app-footer>
