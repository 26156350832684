import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter, takeUntil } from "rxjs/operators";
import { HomeService } from "./services/home.service";
import { IWebsiteHomePage } from "./models/i-website";
import { Subject } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { SharedService } from "./services/shared.service";
import { environment } from "src/environments/environment";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    unsubscriber: Subject<boolean> = new Subject<boolean>();
    location: any;
    routerSubscription: any;
    data: IWebsiteHomePage | null = null;
    navigationLoading: boolean = false;
    backendLoading: boolean = false;

    constructor(
        private router: Router,
        private service: HomeService,
        private sharedService: SharedService,
        private title: Title,
        private meta: Meta
    ) {}

    ngOnInit() {
        // Call Dependencies
        this.recallJsFuntions();
        // Get Backend Data
        this.getBackendData();
    }

    // Handle Navigation Loading and js dependencies
    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.navigationLoading = true;
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                // Load javascript file
                $.getScript(`../assets/js/main.js?v=${environment.jsVersion}`);
                setTimeout(() => {
                    this.navigationLoading = false;
                }, 1200);
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
            });
    }

    // Get Backend Data
    getBackendData(): void {
        this.backendLoading = true;
        // Show Preloader
        $(".preloader").fadeIn(1000);
        // Remote Data
        this.service
            .getWebsiteHomePage()
            .pipe(takeUntil(this.unsubscriber))
            .subscribe({
                next: (response) => {
                    this.data = response?.data ? response.data : null;
                    this.sharedService.data = this.data;
                    this.seo();
                    this.backendLoading = false;
                },
                error: () => {
                    this.backendLoading = false;
                },
            });
    }

    seo(): void {
        // set favicon
        const icon = document.getElementById("favicon");
        const icon2 = document.getElementById("favicon2");
        const icon3 = document.getElementById("favicon3");
        const icon4 = document.getElementById("favicon4");
        if (icon) {
            icon.setAttribute("href", this.data?.contact?.favIcon);
        }
        if (icon2) {
            icon2.setAttribute("href", this.data?.contact?.favIcon);
        }
        if (icon3) {
            icon3.setAttribute("href", this.data?.contact?.favIcon);
        }
        if (icon4) {
            icon4.setAttribute("href", this.data?.contact?.favIcon);
        }
        // set meta tags
        this.title.setTitle(this.data?.website?.metaTitle);
        if (this.data?.website?.metaTitle) {
            this.title.setTitle(this.data?.website?.metaTitle);
            this.meta.updateTag({
                name: "title",
                content: this.data?.website?.metaTitle,
            });
            this.meta.updateTag({
                name: "og:title",
                content: this.data?.website?.metaTitle,
            });
        }
        if (this.data?.website?.metaDescription) {
            this.meta.updateTag({
                name: "description",
                content: this.data.website.metaDescription,
            });
            this.meta.updateTag({
                name: "og:description",
                content: this.data.website.metaDescription,
            });
        }
        if (this.data?.website?.keywords) {
            this.meta.updateTag({
                name: "keywords",
                content: this.data.website.keywords,
            });
        }
        if (this.data?.contact?.logoLight) {
            this.meta.updateTag({
                name: "og:image",
                content: this.data.contact.logoLight,
            });
        }
    }

    ngOnDestroy(): void {
        this.unsubscriber.next(true);
        this.unsubscriber.unsubscribe();
    }
}
