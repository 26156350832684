<section
    class="app-download ptb-100"
    [ngStyle]="{
        'background-image': 'url(' + data.download.backgroundImage + ')'
    }"
>
    <div class="container">
        <div class="app-download-content">
            <h3 class="text-main-light">
                {{
                    data.download.headTitle
                        ? data.download.headTitle
                        : "Download Our App Today"
                }}
            </h3>
            <div class="bar"></div>
            <p
                *ngIf="data.download.headDeescription"
                [innerHTML]="data.download.headDeescription | domSanitizer"
            ></p>
            <div
                class="app-holder d-flex flex-wrap justify-content-center gap-3"
            >
                <a
                    class="mx-0"
                    [attr.href]="data.download.googlePlayLink"
                    target="_blank"
                    *ngIf="data?.download?.googlePlayLink"
                    ><img
                        class="rounded-2 border border-white"
                        width="200"
                        height="auto"
                        [style]="{ height: '58px' }"
                        [attr.src]="
                            data.download?.googlePlayImage
                                ? data.download.googlePlayImage
                                : 'assets/img/store/1.png'
                        "
                        alt="Google Play"
                /></a>
                <a
                    class="mx-0"
                    [attr.href]="data.download.appStoreLink"
                    target="_blank"
                    *ngIf="data?.download?.appStoreLink"
                    ><img
                        class="rounded-2 border border-white"
                        width="200"
                        height="auto"
                        [style]="{ height: '58px' }"
                        [attr.src]="
                            data?.download?.appStoreImage
                                ? data.download.appStoreImage
                                : 'assets/img/store/2.png'
                        "
                        alt="App Store"
                /></a>
                <a
                    class="mx-0"
                    [attr.href]="data.download.appGalleryLink"
                    target="_blank"
                    *ngIf="data?.download?.appGalleryLink"
                    ><img
                        class="rounded-2 border border-white"
                        width="200"
                        height="auto"
                        [style]="{ height: '58px' }"
                        [attr.src]="
                            data?.download?.appGallery
                                ? data.download.appGallery
                                : 'assets/img/store/3.png'
                        "
                        alt="App Gallery"
                /></a>
            </div>
        </div>
    </div>
</section>
