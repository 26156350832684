import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    constructor() {}

    ngOnInit(): void {}
}
