<div
    *ngIf="data"
    class="subscribe-area ptb-100 px-md-2 px-4"
    id="subscription"
    [ngStyle]="{
        'background-image': 'url(' + data?.subscription?.backgroundImage + ')'
    }"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe-content">
                    <h2>
                        {{
                            data?.subscription?.headTitle
                                ? data.subscription.headTitle
                                : "Subscribe For Our Newsletter"
                        }}
                    </h2>
                    <p
                        class="text-white fs-15 mb-md-0 mb-3"
                        *ngIf="data?.subscription?.headDeescription"
                    >
                        {{ data.subscription.headDeescription }}
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <form
                    class="newsletter-form"
                    [formGroup]="subscribeForm"
                    (ngSubmit)="subscribe()"
                >
                    <input
                        type="email"
                        class="input-newsletter"
                        placeholder="Enter your Email"
                        formControlName="email"
                        required
                        autocomplete="off"
                    />
                    <button
                        type="submit"
                        class="default-btn d-flex align-items-center"
                        [disabled]="loading"
                    >
                        <span class="loader me-2" *ngIf="loading"></span>
                        Subscribe Now
                    </button>
                </form>
                <div
                    class="alert alert-success d-flex align-items-start justify-content-center gap-2 mt-3"
                    *ngIf="successMessageShown"
                >
                    <i class="fa fa-check-circle"></i>
                    <span
                        >Thank your for subscribing for our newsletter !!!</span
                    >
                </div>
                <div
                    class="alert alert-danger d-flex align-items-start justify-content-center gap-2 mt-3"
                    *ngIf="failureMessageShown"
                >
                    <i class="fa fa-times-circle"></i>
                    <span
                        >It seems that you are already subscribed to our
                        newsletter! If not, Please try agail later.</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
