<div class="fixed-icons-container">
    <ng-container *ngFor="let item of data.contact.socilaMedia">
        <a
            [attr.href]="item.link"
            target="_blank"
            *ngIf="item.link && item.lightIcon"
        >
            <img
                [attr.src]="item.lightIcon"
                [attr.alt]="item.title"
                width="18"
            />
        </a>
    </ng-container>
</div>
