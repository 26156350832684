<div class="container">
    <div class="section-title">
        <h2>
            {{
                data.testimonials?.headTitle
                    ? data.testimonials.headTitle
                    : "Testimonials"
            }}
        </h2>
        <div class="bar"></div>
        <div
            class="fs-16 text-center"
            [style]="{
                'max-width': '600px',
                'margin-right': 'auto',
                'margin-left': 'auto'
            }"
            *ngIf="data.testimonials?.headDeescription"
            [innerHTML]="data.testimonials.headDeescription"
        ></div>
    </div>
    <div class="testimonials-slides">
        <div class="feedback-carousel owl-carousel owl-theme">
            <ng-container *ngFor="let item of data.testimonials.items">
                <div class="item client-feedback" *ngIf="item.deescription">
                    <div class="single-feedback">
                        <div class="icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <p class="fs-20 fw-bolder">
                            {{ item.deescription }}
                        </p>
                        <div
                            class="wrap d-flex justify-content-center align-items-center gap-2 mt-4"
                        >
                            <img
                                [attr.src]="item.avatar"
                                class="rounded-circle"
                                [attr.alt]="item.by"
                                [style]="{ width: '60px', height: '60px' }"
                            />
                            <div class="inner-text">
                                <h6 class="fw-bold mb-1">{{ item.by }}</h6>
                                <p class="fs-15 mb-0">{{ item.position }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
