<div class="container">
    <div class="section-title">
        <h2>
            {{
                data.faq?.headTitle ? data.faq.headTitle : "Frequently Asked Q"
            }}
        </h2>
        <div class="bar"></div>
        <p *ngIf="data.faq?.headDeescription">
            {{ data.faq.headDeescription }}
        </p>
    </div>
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <ng-container
                        *ngFor="let item of data.faq.faqItems; let i = index"
                    >
                        <div
                            class="card"
                            *ngIf="item.title && item.deescription"
                        >
                            <div class="card-header" [attr.id]="'heading_' + i">
                                <button
                                    [ngClass]="{ collapsed: i > 0 }"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse_' + i"
                                    [attr.aria-expanded]="
                                        i === 0 ? true : false
                                    "
                                    [attr.aria-controls]="'collapse_' + i"
                                >
                                    {{ item.title }}
                                    <i class="fa fa-chevron-down"></i>
                                </button>
                            </div>
                            <div
                                [attr.id]="'collapse_' + i"
                                class="collapse"
                                [ngClass]="{ show: i === 0 }"
                                data-bs-parent="#faqAccordion"
                            >
                                <div class="card-body">
                                    <p
                                        [innerHTML]="
                                            item.deescription | domSanitizer
                                        "
                                    ></p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12" *ngIf="data.faq?.image">
            <div class="faq-image text-center">
                <img
                    class="img img-fluid w-100"
                    [attr.src]="data.faq.image"
                    style="max-width: 500px"
                    alt="Frequently Asked Questions"
                />
            </div>
        </div>
    </div>
</div>
