import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IWebsiteHomePage } from "src/app/models/i-website";
import { HomeService } from "src/app/services/home.service";
import { CustomEmailValidator } from "src/app/validators/custom-email-validator";

@Component({
    selector: "app-subscribe",
    templateUrl: "./subscribe.component.html",
    styleUrls: ["./subscribe.component.scss"],
})
export class SubscribeComponent implements OnInit {
    unsubscriber: Subject<boolean> = new Subject<boolean>();
    @Input("data") data: IWebsiteHomePage | null = null;
    subscribeForm: FormGroup;
    loading: boolean = false;
    successMessageShown: boolean = false;
    failureMessageShown: boolean = false;
    constructor(private fb: FormBuilder, private service: HomeService) {
        this.subscribeForm = this.fb.group({
            email: ["", [Validators.required, CustomEmailValidator]],
        });
    }

    ngOnInit(): void {}

    subscribe(): void {
        if (!this.subscribeForm.valid) {
            this.subscribeForm?.markAllAsTouched();
            return;
        }
        this.loading = true;
        this.service
            .postNewsletterSubscription(this.subscribeForm.value)
            .pipe(takeUntil(this.unsubscriber))
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.successMessageShown = true;
                    this.subscribeForm.reset();
                    setTimeout(() => {
                        this.successMessageShown = false;
                    }, 6000);
                },
                error: () => {
                    this.loading = false;
                    this.failureMessageShown = true;
                    setTimeout(() => {
                        this.failureMessageShown = false;
                    }, 6000);
                },
            });
    }

    ngOnDestroy(): void {
        this.unsubscriber.next(true);
        this.unsubscriber.unsubscribe();
    }
}
