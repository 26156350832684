import { Injectable } from "@angular/core";
import { IWebsiteHomePage } from "../models/i-website";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SharedService {
    data: IWebsiteHomePage | null = null;
    shoppingCartNumberOfItems: number = 0;
    storeNavShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    storeSearchShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    storeSearchKey: BehaviorSubject<string> = new BehaviorSubject<string>("");
    goToCart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    storeNavShown$ = this.storeNavShown.asObservable();
    storeSearchShown$ = this.storeSearchShown.asObservable();
    storeSearchKey$ = this.storeSearchKey.asObservable();
    goToCart$ = this.goToCart.asObservable();
    constructor() {}
}
