<div class="container">
    <div class="section-title">
        <h2>Latest Blog Post</h2>
        <div class="bar"></div>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
            suspendisse ultrices gravida.
        </p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog"
                        ><img src="assets/img/blog/blog1.webp" alt="image"
                    /></a>
                    <div class="category-btn">
                        <a routerLink="/blog">Tracking</a>
                    </div>
                </div>
                <div class="content">
                    <ul class="post-meta">
                        <li><i class="fa fa-calendar"></i> 12 March 2023</li>
                    </ul>
                    <h3>
                        <a routerLink="/single-blog"
                            >Track Your Child Anywhere, And Anytime</a
                        >
                    </h3>
                    <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                    <a routerLink="/single-blog" class="read-more">Read More</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog"
                        ><img src="assets/img/blog/blog2.webp" alt="image"
                    /></a>
                    <div class="category-btn">
                        <a routerLink="/blog">Child Healthcare</a>
                    </div>
                </div>

                <div class="content">
                    <ul class="post-meta">
                        <li><i class="fa fa-calendar"></i> 18 March 2023</li>
                    </ul>
                    <h3>
                        <a routerLink="/single-blog"
                            >Outdoors Tracking for Your Child Health</a
                        >
                    </h3>
                    <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                    <a routerLink="/single-blog" class="read-more">Read More</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="image">
                    <a routerLink="/single-blog"
                        ><img src="assets/img/blog/blog3.webp" alt="image"
                    /></a>
                    <div class="category-btn">
                        <a routerLink="/blog">Valuable Tips</a>
                    </div>
                </div>

                <div class="content">
                    <ul class="post-meta">
                        <li><i class="fa fa-calendar"></i> 28 March 2023</li>
                    </ul>
                    <h3>
                        <a routerLink="/single-blog"
                            >How to follow child status with Yellzee App</a
                        >
                    </h3>
                    <p>Water plan dolor sit amet consturisi velised quiLorem</p>
                    <a routerLink="/single-blog" class="read-more">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>
