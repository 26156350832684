import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-app-screenshots",
    templateUrl: "./app-screenshots.component.html",
    styleUrls: ["./app-screenshots.component.scss"],
})
export class AppScreenshotsComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    constructor() {}

    ngOnInit(): void {}
}
