import { Component, Input, OnInit } from "@angular/core";
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { IWebsiteHomePage, IWebsiteNavigation } from "src/app/models/i-website";
import { AuthenticationService } from "src/app/services/authentication.service";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    currentFragment: string | null = null;
    searchbarShown: boolean = false;
    navbarShown$: Observable<boolean> = new Observable<boolean>();
    searchKey: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public sharedService: SharedService,
        private authService: AuthenticationService,
        private toastService: ToastrService
    ) {}

    goToCart(): void {
        this.sharedService.goToCart.next(true);
    }

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            if (fragment) {
                this.currentFragment = fragment;
                setTimeout(() => {
                    this.navClick();
                }, 1100);
            }
        });
        // NavigationEnd Called with lazy loading
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                setTimeout(() => {
                    this.navClick();
                }, 1100);
            });
        // this.authService.currentUser.subscribe(() => {
        //     if (this.authService.currentUserValue?.token) {
        //         this.sharedService.storeNavShown.next(true);
        //     } else {
        //         this.sharedService.storeNavShown.next(false);
        //     }
        // });
        this.navbarShown$ = this.sharedService.storeNavShown$;
    }

    navClick(sid?: string): void {
        if (sid) {
            const sectionOffset = document.getElementById(sid)?.offsetTop;
            if (sectionOffset) {
                window.scrollTo(0, sectionOffset - 110);
            }
        } else {
            if (this.currentFragment) {
                const sectionOffset = document.getElementById(
                    this.currentFragment
                )?.offsetTop;
                if (sectionOffset) {
                    window.scrollTo(0, sectionOffset - 110);
                }
            }
        }
    }

    goToDownload(target: string = "download"): void {
        const element = document.getElementById(target);
        element?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    get headerLinks(): IWebsiteNavigation[] {
        return this.data?.navigation?.filter(
            (item) => item.isActive && item.showInHeader
        );
    }

    logout(): void {
        this.authService.logout();
        this.toastService.success(
            "You have been successfully logged out.",
            "Logout Completed",
            {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
            }
        );
    }

    search(): void {
        this.sharedService.storeSearchKey.next(this.searchKey);
    }
}
