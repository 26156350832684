import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { IWebsiteHomePage } from "src/app/models/i-website";
import { HomeService } from "src/app/services/home.service";
import { environment } from "src/environments/environment";
declare let $: any;

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit, OnDestroy {
    unsubscriber: Subject<boolean> = new Subject<boolean>();
    data: IWebsiteHomePage | null = null;
    backendLoading: boolean = false;
    constructor(private service: HomeService) {}
    ngOnInit(): void {
        this.getBackendData();
    }

    // Get Backend Data
    getBackendData(): void {
        // Show Preloader
        $(".preloader").fadeIn(1000);
        // Remote Data
        this.service
            .getWebsiteHomePage()
            .pipe(
                takeUntil(this.unsubscriber),
                finalize(() => {
                    this.backendLoading = false;
                    $(".preloader").fadeOut(1000);
                })
            )
            .subscribe({
                next: (response) => {
                    this.data = response?.data ? response.data : null;
                    $.getScript(
                        `../assets/js/main.js?${environment.jsVersion}`
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.unsubscriber.next(true);
        this.unsubscriber.unsubscribe();
    }
}
