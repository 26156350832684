<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-users"></i>
                </div>
                <h3><span>10M</span></h3>
                <p>Usersr</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-heart"></i>
                </div>
                <h3><span>8M</span></h3>
                <p>Happy Clients</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-star"></i>
                </div>
                <h3><span>2M</span></h3>
                <p>Reviews</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa fa-download"></i>
                </div>
                <h3><span>11M</span></h3>
                <p>App Downloads</p>
            </div>
        </div>
    </div>
</div>