import { Component, Input } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-social-media",
    templateUrl: "./social-media.component.html",
    styleUrls: ["./social-media.component.scss"],
})
export class SocialMediaComponent {
    @Input("data") data: IWebsiteHomePage | null = null;
}
