import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LocalStorageUser } from "../models/localstorage-user";
import { IResult } from "../models/IResult";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    //public
    public currentUser: Observable<LocalStorageUser>;

    //private
    public currentUserSubject: BehaviorSubject<LocalStorageUser>;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(private _http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<LocalStorageUser>(
            JSON.parse(sessionStorage.getItem("currentUser"))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    // getter: currentUserValue
    get currentUserValue(): LocalStorageUser {
        return this.currentUserSubject.value;
    }

    getUser(): LocalStorageUser | null {
        const user = sessionStorage?.getItem("currentUser");
        if (user) return JSON.parse(user);
        return null;
    }

    login(phone: string, password: string): Observable<LocalStorageUser> {
        return (
            this._http
                .post<any>(`${environment.baseUrl}mobileapp/appuser/login`, {
                    phone,
                    password,
                })
                // .post<any>(url, null, this.httpOptions)
                .pipe(
                    map((response: IResult<any>) => {
                        const user: LocalStorageUser = response?.data;

                        // login successful if there's a jwt token in the response
                        if (user?.token) {
                            sessionStorage.setItem(
                                "currentUser",
                                JSON.stringify(user)
                            );
                            this.currentUserSubject.next(user);
                        }
                        return user;
                    })
                )
        );
    }

    // User logout
    logout(): void {
        this.currentUserSubject.next(null);
        sessionStorage?.clear();
        this.router.navigateByUrl("/store/login");
    }
}
