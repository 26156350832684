import { AbstractControl, ValidationErrors } from "@angular/forms";

export function PhoneValidator(c: AbstractControl): ValidationErrors | null {
    const val = c?.value;
    const regex = /^[\+]?\d{1,}[0-9\-]?\d{2,4}[0-9\-]?\d{1,4}[0-9\-]?\d{1,5}$/;
    if (val) {
        if (String(val).toLowerCase().match(regex)) {
            return null;
        }
        return { invalidPhone: true };
    } else {
        return null;
    }
}
