import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-testimonials",
    templateUrl: "./testimonials.component.html",
    styleUrls: ["./testimonials.component.scss"],
})
export class TestimonialsComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    constructor() {}

    ngOnInit(): void {}
}
