import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IWebsiteHomePage } from "src/app/models/i-website";
import { HomeService } from "src/app/services/home.service";
import { CustomEmailValidator } from "src/app/validators/custom-email-validator";
import { PhoneValidator } from "src/app/validators/phone-validator";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    unsubscriber: Subject<boolean> = new Subject<boolean>();
    @Input("data") data: IWebsiteHomePage | null = null;
    contactForm: FormGroup;
    loading: boolean = false;
    successMessageShown: boolean = false;
    failureMessageShown: boolean = false;
    constructor(private fb: FormBuilder, private service: HomeService) {
        this.contactForm = this.fb.group({
            name: ["", [Validators.required, Validators.minLength(4)]],
            email: ["", [Validators.required, CustomEmailValidator]],
            phone: ["", [Validators.required, PhoneValidator]],
            subject: ["", [Validators.required, Validators.minLength(4)]],
            message: ["", [Validators.required, Validators.minLength(6)]],
        });
    }

    ngOnInit(): void {}

    sendMessage(): void {
        if (!this.contactForm.valid) {
            this.contactForm?.markAllAsTouched();
            return;
        }
        this.loading = true;
        this.service
            .postContactMessage(this.contactForm.value)
            .pipe(takeUntil(this.unsubscriber))
            .subscribe({
                next: () => {
                    this.loading = false;
                    this.successMessageShown = true;
                    this.contactForm.reset();
                    setTimeout(() => {
                        this.successMessageShown = false;
                    }, 6000);
                },
                error: () => {
                    this.loading = false;
                    this.failureMessageShown = true;
                    setTimeout(() => {
                        this.failureMessageShown = false;
                    }, 6000);
                },
            });
    }

    ngOnDestroy(): void {
        this.unsubscriber.next(true);
        this.unsubscriber.unsubscribe();
    }
}
