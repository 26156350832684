import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";

const routes: Routes = [
    { path: "", component: HomeOneComponent },
    {
        path: "store",
        loadChildren: () =>
            import("./components/pages/store/store.module").then(
                (m) => m.StoreModule
            ),
    },
    {
        path: "**",
        redirectTo: "",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
