<div class="container">
    <div
        class="video-content"
        [ngStyle]="{
            'background-image': 'url(' + data.howToUse.backgroundImage + ')'
        }"
    >
        <h3>{{ data.howToUse.title }}</h3>
        <a [attr.href]="data.howToUse.videoUrl" class="video-btn popup-youtube"
            ><i class="fas fa-play"></i
        ></a>
    </div>
</div>
