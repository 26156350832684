<section id="partners" class="partner-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                <strong class="text-sec d-inline-block mb-2">Yellzee</strong>
                <br />
                {{
                    data.sponsors?.headTitle
                        ? data.sponsors.headTitle
                        : "Thanks To Our Sponsors"
                }}
            </h2>
            <div class="bar"></div>
            <p *ngIf="data.sponsors?.headDeescription">
                {{ data.sponsors.headDeescription }}
            </p>
        </div>
        <div class="partner-slider owl-carousel owl-theme">
            <ng-container *ngFor="let item of data.sponsors.items">
                <div class="partner-item" *ngIf="item">
                    <div class="image">
                        <img [attr.src]="item" alt="Yellzee Sponsor" />
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
