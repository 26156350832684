<div class="container">
    <div class="section-title">
        <h2>
            {{
                data.contact?.headTitle ? data.contact?.headTitle : "Contact US"
            }}
        </h2>
        <div class="bar"></div>
        <p *ngIf="data.contact?.headDeescription">
            {{ data.contact.headDeescription }}
        </p>
    </div>
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div
                class="alert alert-success d-flex align-items-start justify-content-center gap-2"
                *ngIf="successMessageShown"
            >
                <i class="fa fa-check-circle"></i>
                <span
                    >Message Sent Successfully, We will contact you as soon as
                    possible. it may take up to 48 Hrs. Thank You!</span
                >
            </div>
            <div
                class="alert alert-danger d-flex align-items-start justify-content-center gap-2"
                *ngIf="failureMessageShown"
            >
                <i class="fa fa-times-circle"></i>
                <span>Message not sent, Please try again later !!</span>
            </div>
            <div class="contact-form">
                <form
                    id="contactForm"
                    [formGroup]="contactForm"
                    (ngSubmit)="sendMessage()"
                >
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    formControlName="name"
                                    id="name"
                                    class="form-control"
                                    required
                                    placeholder="Your Name"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="email"
                                    formControlName="email"
                                    id="email"
                                    class="form-control"
                                    required
                                    placeholder="Your Email"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    formControlName="phone"
                                    id="phone_number"
                                    required
                                    class="form-control"
                                    placeholder="Your Phone"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    formControlName="subject"
                                    id="msg_subject"
                                    class="form-control"
                                    required
                                    placeholder="Your Subject"
                                />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea
                                    formControlName="message"
                                    class="form-control"
                                    id="message"
                                    cols="30"
                                    rows="6"
                                    required
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button
                                    type="submit"
                                    class="default-btn d-flex align-items-center"
                                    [disabled]="loading"
                                >
                                    <span
                                        class="loader me-2"
                                        *ngIf="loading"
                                    ></span>
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>
                        {{
                            data.contact?.title
                                ? data.contact.title
                                : "Get In Touch Now"
                        }}
                    </h3>
                    <h2>
                        <a
                            *ngIf="data.contact?.phone1"
                            [attr.href]="'tel:' + data.contact.phone1"
                            >{{ data.contact.phone1 }}</a
                        >
                        <ng-container *ngIf="data.contact?.email">
                            <span>Or Via Email :</span>
                            <a [attr.href]="'mailto:' + data.contact.email">{{
                                data.contact.email
                            }}</a>
                        </ng-container>
                    </h2>
                    <ul
                        class="social d-flex flex-wrap justify-content-start align-items-center"
                    >
                        <ng-container
                            *ngFor="let item of data.contact?.socilaMedia"
                        >
                            <li *ngIf="item.link && item.darkIcon">
                                <a [attr.href]="item.link" target="_blank">
                                    <img
                                        [attr.src]="item.darkIcon"
                                        [attr.alt]="item.title"
                                        width="22"
                                    />
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
