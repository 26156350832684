import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    ngOnInit(): void {}
}
