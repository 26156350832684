import { AbstractControl, ValidationErrors } from "@angular/forms";

export function CustomEmailValidator(
    c: AbstractControl
): ValidationErrors | null {
    const val = c?.value;
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val) {
        if (String(val).toLowerCase().match(regex)) {
            return null;
        }
        return { invalidEmail: true };
    } else {
        return null;
    }
}
