import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage, IWebsiteNavigation } from "src/app/models/i-website";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    currentYear: number = new Date().getFullYear();
    constructor() {}

    ngOnInit(): void {}

    goToDownload(target: string = "download"): void {
        const element = document.getElementById(target);
        element?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    get getColumnOneLinks(): IWebsiteNavigation[] {
        return this.data?.navigation?.filter(
            (item) =>
                item.isActive && item.showInFooter && item.column === "Column1"
        );
    }

    get getColumnTwoLinks(): IWebsiteNavigation[] {
        return this.data?.navigation?.filter(
            (item) =>
                item.isActive && item.showInFooter && item.column === "Column2"
        );
    }
}
