<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>
                {{
                    data.screenshots?.headTitle
                        ? data.screenshots.headTitle
                        : "App Screenshots"
                }}
            </h2>
            <div class="bar"></div>
            <p *ngIf="data.screenshots?.headDeescription">
                {{ data.screenshots.headDeescription }}
            </p>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div
                class="screenshot-item"
                *ngFor="let item of data.screenshots.items"
            >
                <div class="image">
                    <img
                        class="rounded-2"
                        [attr.src]="item.image"
                        [attr.alt]="item.title"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
