<div class="container overflow-hidden">
    <div class="section-title">
        <h2>
            {{
                data.about.headTitle
                    ? data.about.headTitle
                    : "About Yellzee App"
            }}
        </h2>
        <div class="bar"></div>
        <p>
            {{ data.about.headDeescription }}
        </p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>{{ data.about.title }}</h3>
                <div class="bar"></div>
                <div [innerHTML]="data.about.deescription | domSanitizer"></div>
                <div class="about-btn">
                    <button (click)="goToDownload()" class="default-btn">
                        Download Now
                    </button>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image text-lg-end text-center">
                <img [attr.src]="data.about.image" alt="About Yellzee" />
            </div>
        </div>
    </div>
</div>
