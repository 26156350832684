import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IResponse } from "../models/i-response";

@Injectable({
    providedIn: "root",
})
export class HomeService {
    baseUrl = environment.apiUrl + "websitehomepage";
    headers = {
        "Content-Type": "application/json",
    };
    constructor(private httpClient: HttpClient) {}

    getWebsiteHomePage(): Observable<any> {
        return this.httpClient.get<IResponse<any>>(this.baseUrl);
    }

    postContactMessage(obj: any): Observable<any> {
        return this.httpClient.post(
            environment.apiUrl + "contactformsubmitted",
            obj
        );
    }

    postNewsletterSubscription(obj: any): Observable<any> {
        return this.httpClient.post(
            environment.apiUrl + "newslettersubscriber",
            obj
        );
    }
}
