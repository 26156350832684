<div class="home-banner" id="homeBanner" *ngIf="data?.sliders?.length">
    <div class="home-banner-slider owl-carousel owl-theme">
        <ng-container *ngFor="let item of data.sliders">
            <div class="item" *ngIf="item.image">
                <img
                    [attr.src]="item.image"
                    [attr.alt]="item.title ? item.title : 'Yellzee'"
                />
            </div>
        </ng-container>
    </div>
</div>

<section id="about" class="about-area pt-100 pb-50" *ngIf="data?.about">
    <app-about [data]="data"></app-about>
    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<section id="howToUse" *ngIf="data?.howToUse" class="pb-100">
    <app-how-it-works [data]="data"></app-how-it-works>
</section>

<section
    id="features"
    *ngIf="data?.features?.items?.length"
    class="features-area pb-70"
>
    <app-features [data]="data"></app-features>
    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<app-app-screenshots
    *ngIf="data?.screenshots?.items?.length"
    [data]="data"
></app-app-screenshots>

<section
    class="testimonials-area overflow-hidden ptb-100"
    id="testimonial"
    *ngIf="data?.testimonials?.items?.length"
>
    <app-testimonials [data]="data"></app-testimonials>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<section class="overview-area ptb-100" *ngIf="data?.overView" id="overview">
    <app-overview [data]="data"></app-overview>
</section>

<section id="faq" class="faq-area ptb-100" *ngIf="data?.faq?.faqItems?.length">
    <app-faq [data]="data"></app-faq>
    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<app-app-download
    id="download"
    *ngIf="data?.download"
    [data]="data"
></app-app-download>

<section id="contact" class="contact-area ptb-100" *ngIf="data?.contact">
    <app-contact [data]="data"></app-contact>
    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
        </div>
    </div>
</section>

<section id="map" class="map-area" *ngIf="data?.contact?.mapURL">
    <app-map [data]="data"></app-map>
</section>

<app-partner *ngIf="data?.sponsors?.items?.length" [data]="data"></app-partner>

<app-subscribe [data]="data"></app-subscribe>

<app-social-media
    [data]="data"
    *ngIf="data?.contact?.socilaMedia?.length"
></app-social-media>
