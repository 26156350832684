import { Component, Input } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-partner",
    templateUrl: "./partner.component.html",
    styleUrls: ["./partner.component.scss"],
})
export class PartnerComponent {
    @Input("data") data: IWebsiteHomePage | null = null;
}
