<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    *ngIf="data?.navigation?.length"
>
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/">
                <img
                    [attr.src]="
                        data?.contact?.logoNormal
                            ? data.contact.logoNormal
                            : 'assets/img/website/logo.png'
                    "
                    alt="Yellzee"
                />
            </a>
        </div>
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav align-items-center">
                <li class="nav-item">
                    <a routerLink="/">Home</a>
                </li>
                <ng-container *ngFor="let item of headerLinks">
                    <li class="nav-item" *ngIf="item.target || item.href">
                        <a
                            *ngIf="item.target"
                            routerLink="/"
                            [fragment]="item.target"
                            class="nav-link"
                        >
                            {{ item.title }}
                        </a>
                        <a
                            *ngIf="!item.target && item.href"
                            [attr.href]="item.href"
                            target="_blank"
                            class="nav-link"
                            >{{ item.title }}</a
                        >
                    </li>
                </ng-container>
                <li class="nav-item">
                    <a routerLink="/store">Store</a>
                </li>
            </ul>
            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <button class="default-btn" (click)="goToDownload()">
                            Try It Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button (click)="goToDownload()" class="default-btn action-float-btn">
        Download App <span></span>
    </button>
</nav>
<div class="store-nav" *ngIf="navbarShown$ | async">
    <div class="container" style="max-width: 1100px">
        <div class="wrapper">
            <input
                type="text"
                class="search-products"
                [ngClass]="{ shown: searchbarShown }"
                placeholder="Search Products ..."
                autofocus="true"
                [(ngModel)]="searchKey"
                (ngModelChange)="search()"
            />
            <i
                class="fa fa-times search-dismiss"
                *ngIf="searchbarShown"
                (click)="searchbarShown = false; searchKey = ''"
            ></i>
            <ul class="links">
                <li>
                    <a
                        routerLink="/store"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <i class="fa fa-list me-1"></i>
                        <span>Products</span>
                    </a>
                </li>
                <li>
                    <a
                        routerLink="/store/orders"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <svg
                            class="me-1"
                            width="19"
                            height="19"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 438.891 438.891"
                            style="enable-background: new 0 0 438.891 438.891"
                            xml:space="preserve"
                        >
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M347.968,57.503h-39.706V39.74c0-5.747-6.269-8.359-12.016-8.359h-30.824c-7.314-20.898-25.6-31.347-46.498-31.347
                                            c-20.668-0.777-39.467,11.896-46.498,31.347h-30.302c-5.747,0-11.494,2.612-11.494,8.359v17.763H90.923
                                            c-23.53,0.251-42.78,18.813-43.886,42.318v299.363c0,22.988,20.898,39.706,43.886,39.706h257.045
                                            c22.988,0,43.886-16.718,43.886-39.706V99.822C390.748,76.316,371.498,57.754,347.968,57.503z M151.527,52.279h28.735
                                            c5.016-0.612,9.045-4.428,9.927-9.404c3.094-13.474,14.915-23.146,28.735-23.51c13.692,0.415,25.335,10.117,28.212,23.51
                                            c0.937,5.148,5.232,9.013,10.449,9.404h29.78v41.796H151.527V52.279z M370.956,399.185c0,11.494-11.494,18.808-22.988,18.808
                                            H90.923c-11.494,0-22.988-7.314-22.988-18.808V99.822c1.066-11.964,10.978-21.201,22.988-21.42h39.706v26.645
                                            c0.552,5.854,5.622,10.233,11.494,9.927h154.122c5.98,0.327,11.209-3.992,12.016-9.927V78.401h39.706
                                            c12.009,0.22,21.922,9.456,22.988,21.42V399.185z"
                                        ></path>
                                        <path
                                            d="M179.217,233.569c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                                            c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                            c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                            C179.628,233.962,179.427,233.761,179.217,233.569z"
                                        ></path>
                                        <path
                                            d="M329.16,256.034H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                            c5.771,0,10.449-4.678,10.449-10.449S334.931,256.034,329.16,256.034z"
                                        ></path>
                                        <path
                                            d="M179.217,149.977c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                                            c-3.919-4.131-10.425-4.364-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                            c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                            C179.628,150.37,179.427,150.169,179.217,149.977z"
                                        ></path>
                                        <path
                                            d="M329.16,172.442H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                            c5.771,0,10.449-4.678,10.449-10.449S334.931,172.442,329.16,172.442z"
                                        ></path>
                                        <path
                                            d="M179.217,317.16c-3.919-4.131-10.425-4.363-14.629-0.522l-33.437,31.869l-14.106-14.629
                                            c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                            c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                            C179.628,317.554,179.427,317.353,179.217,317.16z"
                                        ></path>
                                        <path
                                            d="M329.16,339.626H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                            c5.771,0,10.449-4.678,10.449-10.449S334.931,339.626,329.16,339.626z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                        </svg>
                        <span>My Orders</span>
                    </a>
                </li>
                <li>
                    <button type="button" (click)="logout()">
                        <svg
                            class="me-1"
                            width="19"
                            height="19"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                clip-rule="evenodd"
                                d="m12 2c.5523 0 1 .44772 1 1v10c0 .5523-.4477 1-1 1s-1-.4477-1-1v-10c0-.55228.4477-1 1-1zm-3.13439 3.56953c.27654.47806.11318 1.08979-.36488 1.36633-2.09492 1.21185-3.50073 3.47444-3.50073 6.06414 0 3.866 3.13401 7 7 7 3.866 0 7-3.134 7-7 0-2.5897-1.4058-4.85229-3.5007-6.06414-.4781-.27654-.6414-.88827-.3649-1.36633s.8883-.64142 1.3663-.36488c2.6875 1.55463 4.4993 4.46282 4.4993 7.79535 0 4.9706-4.0294 9-9 9-4.97056 0-9-4.0294-9-9 0-3.33253 1.81178-6.24072 4.49927-7.79535.47806-.27654 1.08979-.11318 1.36634.36488z"
                                fill="rgb(0,0,0)"
                                fill-rule="evenodd"
                            ></path>
                        </svg>
                        <span>Sign Out</span>
                    </button>
                </li>
            </ul>
            <div class="actions">
                <button class="me-2" (click)="searchbarShown = true">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 310.42 310.42"
                        style="enable-background: new 0 0 310.42 310.42"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    d="M273.587,214.965c49.11-49.111,49.109-129.021,0-178.132c-49.111-49.111-129.02-49.111-178.13,0
                                    C53.793,78.497,47.483,140.462,76.51,188.85c0,0,2.085,3.498-0.731,6.312c-16.065,16.064-64.263,64.263-64.263,64.263
                                    c-12.791,12.79-15.836,30.675-4.493,42.02l1.953,1.951c11.343,11.345,29.229,8.301,42.019-4.49c0,0,48.096-48.097,64.128-64.128
                                    c2.951-2.951,6.448-0.866,6.448-0.866C169.958,262.938,231.923,256.629,273.587,214.965z M118.711,191.71
                                    c-36.288-36.288-36.287-95.332,0.001-131.62c36.288-36.287,95.332-36.288,131.619,0c36.288,36.287,36.288,95.332,0,131.62
                                    C214.043,227.996,155,227.996,118.711,191.71z"
                                ></path>
                                <g>
                                    <path
                                        d="M126.75,118.424c-1.689,0-3.406-0.332-5.061-1.031c-6.611-2.798-9.704-10.426-6.906-17.038
                                        c17.586-41.559,65.703-61.062,107.261-43.476c6.611,2.798,9.704,10.426,6.906,17.038c-2.799,6.612-10.425,9.703-17.039,6.906
                                        c-28.354-11.998-61.186,1.309-73.183,29.663C136.629,115.445,131.815,118.424,126.75,118.424z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                    </svg>
                </button>
                <button (click)="goToCart()">
                    <svg
                        enable-background="new 0 0 512 512"
                        width="24"
                        height="24"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g>
                            <path
                                d="m172.5 444.5-15 30 15 30c16.569 0 30-13.431 30-30s-13.431-30-30-30z"
                                fill="#234b72"
                            ></path>
                            <path
                                d="m142.5 474.5c0 16.569 13.431 30 30 30v-60c-16.569 0-30 13.431-30 30z"
                                fill="#375798"
                            ></path>
                            <path
                                d="m429.5 444.5-15 30 15 30c16.569 0 30-13.431 30-30s-13.431-30-30-30z"
                                fill="#234b72"
                            ></path>
                            <path
                                d="m399.5 474.5c0 16.569 13.431 30 30 30v-60c-16.569 0-30 13.431-30 30z"
                                fill="#375798"
                            ></path>
                            <path
                                d="m459.5 384.5h-30v30h30c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                                fill="#a0b4de"
                            ></path>
                            <path
                                d="m271 324.5h30c16.568 0 30-13.432 30-30h-30z"
                                fill="#a0b4de"
                            ></path>
                            <path
                                d="m429.5 384.5h-302c-8.271 0-15-6.729-15-15 0-5.393 2.926-10.398 7.646-13.07l56.314-31.93h94.54c16.568 0 30-13.432 30-30h-116.851l-58.98-230.6c-3.423-13.406-10.338-25.176-19.62-34.45h-21.215v21.216c5.568 5.562 9.716 12.621 11.77 20.662l59.025 230.779-49.771 28.22c-14.099 7.983-22.858 22.992-22.858 39.173 0 24.813 20.187 45 45 45h302c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                                fill="#b5cdff"
                            ></path>
                            <path
                                d="m52.5 7.5h-30c-8.284 0-15 6.716-15 15s6.716 15 15 15h30c12.277 0 23.607 4.948 31.834 13.166l21.216-21.216c-13.711-13.7-32.592-21.95-53.05-21.95z"
                                fill="#ff4c3b"
                            ></path>
                            <path
                                d="m172.5 437c-20.678 0-37.5 16.822-37.5 37.5s16.822 37.5 37.5 37.5 37.5-16.822 37.5-37.5-16.822-37.5-37.5-37.5zm0 60c-12.406 0-22.5-10.094-22.5-22.5s10.094-22.5 22.5-22.5 22.5 10.094 22.5 22.5-10.094 22.5-22.5 22.5z"
                            ></path>
                            <circle cx="172.5" cy="474.5" r="7.5"></circle>
                            <circle cx="429.5" cy="474.5" r="7.5"></circle>
                            <path
                                d="m429.5 437c-20.678 0-37.5 16.822-37.5 37.5s16.822 37.5 37.5 37.5 37.5-16.822 37.5-37.5-16.822-37.5-37.5-37.5zm0 60c-12.406 0-22.5-10.094-22.5-22.5s10.094-22.5 22.5-22.5 22.5 10.094 22.5 22.5-10.094 22.5-22.5 22.5z"
                            ></path>
                            <path
                                d="m123.846 362.954 54.592-30.954h122.562c18.109 0 33.262-12.904 36.747-30h78.11c24.002 0 44.917-16.24 50.863-39.493l45.046-176.148c.573-2.245.078-4.628-1.343-6.458s-3.607-2.901-5.923-2.901h-368.238l-3.825-14.955c-9.33-36.531-42.201-62.045-79.937-62.045h-30c-12.406 0-22.5 10.094-22.5 22.5s10.094 22.5 22.5 22.5h30c17.157 0 32.099 11.59 36.338 28.187l57.604 225.225-44.78 25.39c-16.445 9.31-26.662 26.821-26.662 45.698 0 28.948 23.552 52.5 52.5 52.5h332c12.406 0 22.5-10.094 22.5-22.5s-10.094-22.5-22.5-22.5h-332c-4.136 0-7.5-3.364-7.5-7.5 0-2.695 1.472-5.202 3.846-6.546zm277.997-145.954h-46.089l2.812-55h51.715zm23.613-55h51.484l-14.065 55h-45.857zm-25.915 70-8.438 55h-38.928l2.812-55zm-58.807-15h-46.167l-2.814-55h51.793zm18.599-70 2.812-55h58.875l-8.438 55zm-15.02 0h-53.328l-2.814-55h58.954zm-68.347 0h-29.687c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h30.454l2.814 55h-46.088l-19.183-125h58.875zm-57.682 70h-46.216l-14.067-55h51.842zm2.302 15 8.441 55h-39.055l-14.067-55zm23.616 55-8.441-55h44.554l2.814 55zm53.947 0-2.814-55h44.632l-2.812 55zm154.039-28.209c-4.247 16.608-19.187 28.209-36.331 28.209h-9.579l8.438-55h44.322zm28.588-111.791h-53.018l8.438-55h58.645zm-281.675-55 8.441 55h-53.376l-14.067-55zm-146.601-62h-30c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5h30c15.765 0 30.484 5.451 42.138 14.754l-10.693 10.694c-8.843-6.607-19.775-10.448-31.445-10.448zm75 362h332c4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5h-332c-20.678 0-37.5-16.822-37.5-37.5 0-13.483 7.301-25.992 19.057-32.648l49.771-28.22c2.944-1.67 4.405-5.104 3.566-8.383l-59.024-230.776c-1.743-6.826-4.786-13.04-8.813-18.422l10.696-10.696c5.837 7.302 10.223 15.894 12.651 25.404l58.979 230.6c.849 3.319 3.839 5.642 7.266 5.642h138.066c-3.096 8.73-11.437 15-21.215 15h-124.54c-1.297 0-2.571.336-3.699.976l-56.309 31.927c-7.063 3.996-11.452 11.506-11.452 19.596 0 12.406 10.094 22.5 22.5 22.5z"
                            ></path>
                        </g>
                    </svg>
                    <span class="badge-sm">{{
                        sharedService?.shoppingCartNumberOfItems
                    }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
