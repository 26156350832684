import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-how-it-works",
    templateUrl: "./how-it-works.component.html",
    styleUrls: ["./how-it-works.component.scss"],
})
export class HowItWorksComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    constructor() {}

    ngOnInit(): void {}
}
