<section
    class="footer-area text-md-start text-center ptb-70 border-top"
    id="footer"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img
                            [attr.src]="
                                data.contact?.logoNormal
                                    ? data.contact.logoNormal
                                    : 'assets/img/website/logo.png'
                            "
                            width="180"
                            alt="Yellzee"
                        />
                    </a>
                    <p *ngIf="data.footer?.description">
                        {{ data.footer.description }}
                    </p>
                    <ul class="social-list">
                        <ng-container
                            *ngFor="let item of data.contact?.socilaMedia"
                        >
                            <li *ngIf="item.lightIcon && item.link">
                                <a [attr.href]="item.link" target="_blank"
                                    ><img
                                        [attr.src]="item.lightIcon"
                                        [attr.alt]="item.title"
                                /></a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget ps-md-5">
                    <h3>
                        {{
                            data.footer?.columnOneTitle
                                ? data.footer?.columnOneTitle
                                : "Quick Linkss"
                        }}
                    </h3>
                    <ul class="list">
                        <ng-container *ngFor="let item of getColumnOneLinks">
                            <li *ngIf="item.target">
                                <button (click)="goToDownload(item.target)">
                                    {{ item.title }}
                                </button>
                            </li>
                            <li *ngIf="item.href && !item.target">
                                <a [attr.href]="item.href" target="_blank">
                                    {{ item.title }}
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>
                        {{
                            data.footer?.columnTwoTitle
                                ? data.footer?.columnTwoTitle
                                : "More Links"
                        }}
                    </h3>
                    <ul class="list">
                        <ng-container *ngFor="let item of getColumnTwoLinks">
                            <li *ngIf="item.target">
                                <button (click)="goToDownload(item.target)">
                                    {{ item.title }}
                                </button>
                            </li>
                            <li *ngIf="item.href && !item.target">
                                <a [attr.href]="item.href" target="_blank">
                                    {{ item.title }}
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="data.privacyPolicy">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#privacyModal"
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li *ngIf="data.terms">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#termsModal"
                            >
                                Terms & Conditions
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>
                        {{
                            data.footer?.downloadTitle
                                ? data.footer?.downloadTitle
                                : "Download"
                        }}
                    </h3>
                    <ul class="footer-holder">
                        <li *ngIf="data.download?.googlePlayLink">
                            <a
                                [attr.href]="data.download.googlePlayLink"
                                target="_blank"
                                ><img
                                    width="200"
                                    height="auto"
                                    [style]="{ height: '58px' }"
                                    [attr.src]="
                                        data.download?.googlePlayImage
                                            ? data.download.googlePlayImage
                                            : 'assets/img/store/1.png'
                                    "
                                    alt="image"
                            /></a>
                        </li>
                        <li *ngIf="data.download?.appStoreLink">
                            <a
                                [attr.href]="data.download.appStoreLink"
                                target="_blank"
                                ><img
                                    width="200"
                                    height="auto"
                                    [style]="{ height: '58px' }"
                                    [attr.src]="
                                        data.download?.appStoreImage
                                            ? data.download.appStoreImage
                                            : 'assets/img/store/2.png'
                                    "
                                    alt="image"
                            /></a>
                        </li>
                        <li *ngIf="data.download?.appGalleryLink">
                            <a
                                [attr.href]="data.download.appGalleryLink"
                                target="_blank"
                                ><img
                                    width="200"
                                    height="auto"
                                    [style]="{ height: '58px' }"
                                    [attr.src]="
                                        data.download?.appGallery
                                            ? data.download.appGallery
                                            : 'assets/img/store/3.png'
                                    "
                                    alt="image"
                            /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right pb-sm-4 pb-5">
    <div class="container">
        <div class="copy-right-content">
            <div
                *ngIf="data.footer?.copyRightText"
                [innerHTML]="data.footer.copyRightText | domSanitizer"
            ></div>
            <div *ngIf="!data.footer?.copyRightText">
                © Copyright <strong class="text-sec">Yellzee</strong>
                {{ currentYear }}, All Rights Reserved.
            </div>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>

<div
    class="modal fade"
    id="privacyModal"
    aria-hidden="true"
    aria-labelledby="privacyModalLabel"
    tabindex="-1"
>
    <div
        class="modal-dialog modal-dialog-centered"
        [style]="{ 'max-width': '650px' }"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="privacyModalLabel">
                    Privacy Policy
                </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div
                class="modal-body"
                [innerHTML]="data.privacyPolicy | domSanitizer"
            ></div>
            <div class="modal-footer">
                <button
                    class="default-btn sm"
                    data-bs-target="#privacyModal"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                >
                    Close Privacy Policy
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="termsModal"
    aria-hidden="true"
    aria-labelledby="termsModalLabel"
    tabindex="-1"
>
    <div
        class="modal-dialog modal-dialog-centered"
        [style]="{ 'max-width': '650px' }"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="termsModalLabel">
                    Terms & Conditions
                </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div
                class="modal-body"
                [innerHTML]="data.terms | domSanitizer"
            ></div>
            <div class="modal-footer">
                <button
                    class="default-btn sm"
                    data-bs-target="#termsModal"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                >
                    Close Terms & Conditions
                </button>
            </div>
        </div>
    </div>
</div>
