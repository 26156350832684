import { Component, Input, OnInit } from "@angular/core";
import { IWebsiteHomePage } from "src/app/models/i-website";

@Component({
    selector: "app-overview",
    templateUrl: "./overview.component.html",
    styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent implements OnInit {
    @Input("data") data: IWebsiteHomePage | null = null;
    constructor() {}

    ngOnInit(): void {}

    goToDownload(): void {
        const element = document.getElementById("download");
        element?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
}
